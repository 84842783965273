<template>
  <div class="main-container" v-if="isError">
    <page-error-view text="System is not active yet in this environment" />
  </div>
  <div class="main-container" v-else>
    <parameter-override-popup
      :popup-open="parameterPopupOpen"
      :data="
        editingBucketIndex != -1
          ? configObj[
              isEditingUnitBucket
                ? 'unit_based_activation_conditions'
                : 'activation_conditions'
            ][editingBucketIndex].custom_parameters
          : {}
      "
      :isOverrideActive="
        editingBucketIndex != -1
          ? configObj[
              isEditingUnitBucket
                ? 'unit_based_activation_conditions'
                : 'activation_conditions'
            ][editingBucketIndex].parameter_override_active
          : false
      "
      :itemIndex="editingBucketIndex"
      @popup-close="parameterPopupOpen = false"
      @popup-save="(isActive, state) => onPopupSave(isActive, state)"
    />
    <div class="main-heading">
      <h3>GOD MODE Control Panel</h3>
      <Button
        type="info"
        text="Save & Apply"
        :onClick="submit"
        size="md"
        :loading="isLoading"
        :disabled="isLoading || isSwitching"
      />
    </div>
    <div class="main-heading" style="background-color: white; color: black">
      <h3>
        {{
          "System State is: " +
          (configObj.is_system_active ? "ACTIVE" : "INACTIVE")
        }}
      </h3>

      <Button
        :type="configObj.is_system_active ? 'error' : 'success'"
        :text="configObj.is_system_active ? 'Deactivate' : 'Activate'"
        :onClick="switchSystemIsActive"
        size="md"
        :loading="isSwitching"
        :disabled="isLoading || isSwitching"
      />
    </div>
    <div class="full-width-content-container">
      <h2>Match Activation Parameters</h2>
      <div class="content-container">
        <div class="field-container section">
          <label>Is System Active For Real PvP</label>
          <input type="checkbox" v-model="configObj.is_real_pvp_active" />
        </div>
        <div class="field-container section">
          <label>Domant User Days Threshold</label>
          <input
            type="number"
            v-model="
              configObj.predefined_activation_conditions
                .dormant_user_days_threshold
            "
          />
        </div>
        <div class="field-container section">
          <label>Domant User Boost Match Count</label>
          <input
            type="number"
            v-model="
              configObj.predefined_activation_conditions
                .dormant_user_boost_match_count
            "
          />
        </div>
        <div class="field-container section">
          <label>Purchased User Boost Match Count</label>
          <input
            type="number"
            v-model="
              configObj.predefined_activation_conditions
                .purchased_user_boost_match_count
            "
          />
        </div>
      </div>
      <h3>Special Buckets</h3>
      <p>
        Order of each configuration is important. Evaluation order will be the
        same in game.
      </p>
      <div>
        <div class="condition-container bold-font">
          <p>Min Trophy</p>
          <p>Max Trophy</p>
          <p>Min Win Rate</p>
          <p>Max Win Rate</p>
          <p>Win Streak</p>
          <p>Lose Streak</p>
          <p>User Segments</p>
          <p>Countries</p>
          <p>Probability</p>
          <p>Mode</p>
          <Button
            :disabled="false"
            :loading="false"
            text="Add"
            :onClick="addConfiguration"
            size="sm"
            type="success"
          />
        </div>
        <div
          class="condition-container"
          v-for="(element, index) in configObj.activation_conditions"
          v-bind:key="index"
        >
          <input
            style="margin-right: 10px"
            type="number"
            v-model="element.trophy"
          />
          <input
            style="margin-right: 10px"
            type="number"
            v-model="element.max_trophy"
          />
          <input
            style="margin-right: 10px"
            type="number"
            v-model="element.winrate"
          />
          <input
            style="margin-right: 10px"
            type="number"
            v-model="element.max_winrate"
          />
          <input
            style="margin-right: 10px"
            type="number"
            v-model="element.win_streak"
          />
          <input
            style="margin-right: 10px"
            type="number"
            v-model="element.lose_streak"
          />
          <multiselect
            class="input"
            v-model="element.segments"
            :options="userSegmentOptions.map((x) => x.value)"
            :multiple="true"
            placeholder="All"
          >
            <template v-slot:tag="slotProps">
              <span
                ><span>{{
                  userSegmentOptions.find((x) => x.value === slotProps.option)
                    .text + ", "
                }}</span></span
              >
            </template>
            <template v-slot:option="slotProps">
              <span class="option__desc"
                ><span class="option__title">{{
                  userSegmentOptions.find((x) => x.value === slotProps.option)
                    .text
                }}</span></span
              >
            </template>
          </multiselect>
          <multiselect
            class="input"
            v-model="element.countries"
            :options="countryOptions.map((x) => x.code)"
            :multiple="true"
            placeholder="All Countries"
            limit="6"
          >
            <template v-slot:option="slotProps">
              <span class="option__desc"
                ><span class="option__title">{{
                  countryOptions.find((x) => x.code === slotProps.option).name
                }}</span></span
              >
            </template>
          </multiselect>
          <input
            style="margin-right: 10px"
            type="number"
            v-model="element.probability"
          />
          <select class="input" v-model="element.mode">
            <option
              v-for="mode in modes"
              v-bind:key="mode.value"
              :value="mode.value"
            >
              {{ mode.text }}
            </option>
          </select>
          <div class="activation-config-button-container">
            <i
              class="fa fa-pen-to-square clickable-icon"
              @click="() => openParameterPopup(index, false)"
            ></i>
            <i
              class="fa fa-arrow-up clickable-icon"
              @click="() => toggleConditionPosition(index, true)"
            ></i>
            <i
              class="fa fa-arrow-down clickable-icon"
              @click="() => toggleConditionPosition(index, false)"
            ></i>
            <i
              class="fa fa-xmark clickable-icon"
              @click="() => removeConfiguration(index)"
            ></i>
          </div>
        </div>
      </div>
      <h3>Bot Unit Based Buckets</h3>
      <p>
        Order of each configuration is important. Evaluation order will be the
        same in game.
      </p>
      <div>
        <div class="condition-container smaller-grid bold-font">
          <p>Bot Unit</p>
          <p>Min Trophy</p>
          <p>Max Trophy</p>
          <p>Probability</p>
          <p>Mode</p>
          <Button
            :disabled="false"
            :loading="false"
            text="Add"
            :onClick="addUnitConfiguration"
            size="sm"
            type="success"
          />
        </div>
        <div
          class="condition-container smaller-grid"
          v-for="(element, index) in configObj.unit_based_activation_conditions"
          v-bind:key="index"
        >
          <select class="input" v-model="element.unit_index">
            <option
              v-for="unit in [
                { index: -1, name: 'EMPTY UNIT INDEX' },
                ...units
                  .filter((x) => x.status === 1)
                  .sort((a, b) => a.name.localeCompare(b.name)),
              ]"
              v-bind:key="unit.index"
              :value="unit.index"
            >
              {{ unit.name }}
            </option>
          </select>
          <input
            style="margin-right: 10px"
            type="number"
            v-model="element.trophy"
          />
          <input
            style="margin-right: 10px"
            type="number"
            v-model="element.max_trophy"
          />
          <input
            style="margin-right: 10px"
            type="number"
            v-model="element.probability"
          />
          <select class="input" v-model="element.mode">
            <option
              v-for="mode in modes"
              v-bind:key="mode.value"
              :value="mode.value"
            >
              {{ mode.text }}
            </option>
          </select>
          <div class="activation-config-button-container">
            <i
              class="fa fa-pen-to-square clickable-icon"
              @click="() => openParameterPopup(index, true)"
            ></i>
            <i
              class="fa fa-arrow-up clickable-icon"
              @click="() => toggleUnitConditionPosition(index, true)"
            ></i>
            <i
              class="fa fa-arrow-down clickable-icon"
              @click="() => toggleUnitConditionPosition(index, false)"
            ></i>
            <i
              class="fa fa-xmark clickable-icon"
              @click="() => removeUnitConfiguration(index)"
            ></i>
          </div>
        </div>
      </div>
    </div>

    <hr class="dashed" />
    <div class="inner-container">
      <div class="content-container">
        <h2>Activation Parameters</h2>
        <div class="field-container section">
          <label>Check Loop Interval (Seconds)</label>
          <input type="number" v-model="configObj.check_loop_interval" />
        </div>
        <div class="section">
          <h4>Min Enemy Count for Activation</h4>
          <div
            v-for="(element, index) in [0, 1, 2, 3, 4]"
            v-bind:key="index"
            class="field-container inner"
          >
            <label>{{ "Wave " + (element + 1) }}</label>
            <input
              type="number"
              v-model="configObj.min_enemy_count_for_activation[index]"
            />
          </div>
        </div>
        <div class="section">
          <h4>Trigger Threshold</h4>
          <div
            v-for="(element, index) in [0, 1, 2, 3, 4]"
            v-bind:key="index"
            class="field-container inner"
          >
            <label>{{ "Wave " + (element + 1) }}</label>
            <input type="number" v-model="configObj.trigger_threshold[index]" />
          </div>
        </div>
        <div class="section">
          <h4>Hard Mode Trigger Threshold</h4>
          <div
            v-for="(element, index) in [0, 1, 2, 3, 4]"
            v-bind:key="index"
            class="field-container inner"
          >
            <label>{{ "Wave " + (element + 1) }}</label>
            <input
              type="number"
              v-model="configObj.hard_mode_trigger_threshold[index]"
            />
          </div>
        </div>
        <div class="section">
          <h4>Wave Based Cumulative Score Multiplier (%)</h4>
          <div
            v-for="(element, index) in [0, 1, 2, 3, 4]"
            v-bind:key="index"
            class="field-container inner"
          >
            <label>{{ "Wave " + (element + 1) }}</label>
            <input
              type="number"
              v-model="configObj.wave_based_cumulative_scores[index]"
            />
          </div>
        </div>
        <div class="section">
          <h4>Enemy Type Multipliers</h4>
          <div class="field-container inner">
            <label>{{ "Monster" }}</label>
            <input
              type="number"
              v-model="configObj.enemy_type_multipliers.monster"
            />
          </div>
          <div class="field-container inner">
            <label>{{ "Miniboss" }}</label>
            <input
              type="number"
              v-model="configObj.enemy_type_multipliers.miniboss"
            />
          </div>
          <div class="field-container inner">
            <label>{{ "Boss" }}</label>
            <input
              type="number"
              v-model="configObj.enemy_type_multipliers.boss"
            />
          </div>
        </div>
        <div class="field-container section">
          <label>Percentage Boost For Missing Health</label>
          <input
            type="number"
            v-model="configObj.percentage_boost_for_missing_health"
          />
        </div>
        <div class="field-container section">
          <label>Hard Mode Min Enemy Path Position</label>
          <input
            type="number"
            v-model="configObj.hard_mode_min_enemy_position"
          />
        </div>
        <div class="section">
          <h4>Mana Multipliers</h4>
          <div
            v-for="(element, index) in configObj.mana_multipliers"
            v-bind:key="index"
            class="field-container inner"
          >
            <label>{{ "Upper Ratio" }}</label>
            <input
              type="number"
              v-model="configObj.mana_multipliers[index].upper_ratio"
            />
            <label>{{ "Multiplier" }}</label>
            <input
              type="number"
              v-model="configObj.mana_multipliers[index].value"
            />
          </div>
        </div>
      </div>
      <div class="content-container">
        <h2>Manipulation Parameters</h2>
        <div class="field-container section">
          <label>Unit Manipulation Probability</label>
          <input
            type="number"
            v-model="configObj.unit_manipulation_probability"
          />
        </div>
        <div class="field-container section">
          <label>Hard Mode Unit Manipulation Probability</label>
          <input
            type="number"
            v-model="configObj.hard_mode_unit_manipulation_probability"
          />
        </div>
        <div class="field-container section">
          <label>Grid Slot Manipulation Probability</label>
          <input
            type="number"
            v-model="configObj.grid_slot_manipulation_probability"
          />
        </div>
        <div class="field-container section">
          <label>Hard Mode Grid Slot Manipulation Probability</label>
          <input
            type="number"
            v-model="configObj.hard_mode_grid_slot_manipulation_probability"
          />
        </div>
        <div class="field-container section">
          <label>Chameleon Probability for Manipulated Summon</label>
          <input
            type="number"
            v-model="configObj.chameleon_probability_for_manipulated_unit"
          />
        </div>
        <div class="field-container section">
          <label>Voodoo Outcome Manipulation Probability</label>
          <input
            type="number"
            v-model="configObj.voodoo_manipulation_probability"
          />
        </div>
        <div class="field-container section">
          <label>Hard Mode Voodoo Outcome Manipulation Probability</label>
          <input
            type="number"
            v-model="configObj.hard_mode_voodoo_manipulation_probability"
          />
        </div>
        <div class="field-container section">
          <label>Consecutive Unit Manipulation Limit</label>
          <input
            type="number"
            v-model="configObj.consecutive_manipulation_limit"
          />
        </div>
        <div class="field-container section">
          <label>Manipulated Critical Rate</label>
          <input type="number" v-model="configObj.manipulated_critical_rate" />
        </div>
        <div class="field-container section">
          <label>Hard Mode Manipulated Critical Rate</label>
          <input
            type="number"
            v-model="configObj.hard_mode_manipulated_critical_rate"
          />
        </div>
        <div style="margin-top: 60px">
          <h3>Unit Priorities</h3>
          <div class="unit-priority-container condition-container bold-font">
            <p>Unit</p>
            <Button
              :disabled="false"
              :loading="false"
              text="Add"
              :onClick="addUnitPriority"
              size="sm"
              type="success"
            />
          </div>
          <div
            class="unit-priority-container condition-container"
            v-for="(element, index) in configObj.non_specific_unit_priorities"
            v-bind:key="index"
          >
            <select class="input" v-model="element.unit_index">
              <option
                v-for="unit in [
                  { index: -1, name: 'EMPTY UNIT INDEX' },
                  ...units
                    .filter((x) => x.status === 1)
                    .sort((a, b) => a.name.localeCompare(b.name)),
                ]"
                v-bind:key="unit.index"
                :value="unit.index"
              >
                {{ unit.name }}
              </option>
            </select>
            <div class="activation-config-button-container">
              <i
                class="fa fa-arrow-up clickable-icon"
                @click="() => toggleUnitPriority(index, true)"
              ></i>
              <i
                class="fa fa-arrow-down clickable-icon"
                @click="() => toggleUnitPriority(index, false)"
              ></i>
              <i
                class="fa fa-xmark clickable-icon"
                @click="() => removeUnitPriority(index)"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  watch,
  ref,
  onBeforeUnmount,
  onBeforeMount,
  onMounted,
  computed,
} from "vue";
import { useStore } from "vuex";
import dispatchMap from "@/constants/dispatchMap";
import Button from "../../../components/common/Button.vue";
import PageErrorView from "../../../components/common/PageErrorView.vue";
import countryConstants from "../../../constants/countryConstants";
import ParameterOverridePopup from "./ParameterOverridePopup.vue";

export default {
  name: "DynamicDifficultySystem",
  components: {
    Button,
    PageErrorView,
    ParameterOverridePopup,
  },
  setup() {
    const store = useStore();
    const usedPages = ["dynamicDifficulty"];
    const configObj = ref({});
    const units = computed(() => store.getters["units/getUnits"]);

    const isError = ref(false);
    const isLoading = ref(false);
    const isSwitching = ref(false);
    const parameterPopupOpen = ref(false);
    const editingBucketIndex = ref(-1);
    const isEditingUnitBucket = ref(false);

    onBeforeMount(() => {
      usedPages.forEach((page) => {
        dispatchMap[page].forEach((dispatchStr) => {
          store.dispatch(dispatchStr).then((val) => {
            if (val) return;
            isError.value = true;
          });
        });
      });
    });

    onMounted(() => store.dispatch("loader/loadingStatus", false));
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    watch(
      () => store.getters["serverConfig/getDynamicDifficultyConfig"],
      (value) => {
        if (!value) {
          return;
        }
        configObj.value = JSON.parse(JSON.stringify(value));
        console.log(configObj.value);
      }
    );

    const addConfiguration = () => {
      configObj.value.activation_conditions.push({
        trophy: 0,
        max_trophy: 0,
        winrate: 0,
        max_winrate: 0,
        win_streak: 0,
        lose_streak: 0,
        probability: 100,
        mode: 0,
        segments: [],
        countries: [],
        parameter_override_active: false,
        custom_parameters: {},
      });
    };

    const addUnitConfiguration = () => {
      configObj.value.unit_based_activation_conditions.push({
        unit_index: -1,
        trophy: 0,
        max_trophy: 0,
        probability: 100,
        mode: 0,
        parameter_override_active: false,
        custom_parameters: {},
      });
    };

    const addUnitPriority = () => {
      configObj.value.non_specific_unit_priorities.push({ unit_index: -1 });
    };

    const removeUnitPriority = (index) => {
      configObj.value.non_specific_unit_priorities.splice(index, 1);
    };

    const removeConfiguration = (index) => {
      configObj.value.activation_conditions.splice(index, 1);
    };

    const removeUnitConfiguration = (index) => {
      configObj.value.unit_based_activation_conditions.splice(index, 1);
    };

    const switchSystemIsActive = () => {
      configObj.value.is_system_active = !configObj.value.is_system_active;
      isSwitching.value = true;
      submit();
    };

    const openParameterPopup = (index, isUnitBucket) => {
      isEditingUnitBucket.value = isUnitBucket;
      editingBucketIndex.value = index;
      parameterPopupOpen.value = true;
    };

    const toggleConditionPosition = (index, isDirectionUp) => {
      if (
        (index === 0 && isDirectionUp) ||
        (index === configObj.value.activation_conditions.length - 1 &&
          !isDirectionUp)
      )
        return;

      let newIndex = index;

      if (isDirectionUp) index--;
      else index++;

      const element = JSON.parse(
        JSON.stringify(configObj.value.activation_conditions[index])
      );
      configObj.value.activation_conditions.splice(index, 1);
      configObj.value.activation_conditions = [
        ...configObj.value.activation_conditions.slice(0, newIndex),
        element,
        ...configObj.value.activation_conditions.slice(newIndex),
      ];
    };

    const toggleUnitConditionPosition = (index, isDirectionUp) => {
      if (
        (index === 0 && isDirectionUp) ||
        (index ===
          configObj.value.unit_based_activation_conditions.length - 1 &&
          !isDirectionUp)
      )
        return;

      let newIndex = index;

      if (isDirectionUp) index--;
      else index++;

      const element = JSON.parse(
        JSON.stringify(configObj.value.unit_based_activation_conditions[index])
      );
      configObj.value.unit_based_activation_conditions.splice(index, 1);
      configObj.value.unit_based_activation_conditions = [
        ...configObj.value.unit_based_activation_conditions.slice(0, newIndex),
        element,
        ...configObj.value.unit_based_activation_conditions.slice(newIndex),
      ];
    };

    const toggleUnitPriority = (index, isDirectionUp) => {
      if (
        (index === 0 && isDirectionUp) ||
        (index === configObj.value.non_specific_unit_priorities.length - 1 &&
          !isDirectionUp)
      )
        return;

      let newIndex = index;

      if (isDirectionUp) index--;
      else index++;

      const element = JSON.parse(
        JSON.stringify(configObj.value.non_specific_unit_priorities[index])
      );
      configObj.value.non_specific_unit_priorities.splice(index, 1);
      configObj.value.non_specific_unit_priorities = [
        ...configObj.value.non_specific_unit_priorities.slice(0, newIndex),
        element,
        ...configObj.value.non_specific_unit_priorities.slice(newIndex),
      ];
    };

    const submit = () => {
      isLoading.value = true;
      store
        .dispatch("serverConfig/updateDynamicDifficultyConfig", configObj.value)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          isLoading.value = false;
          isSwitching.value = false;
        });
    };

    const onPopupSave = (isActive, state) => {
      configObj.value[
        isEditingUnitBucket.value
          ? "unit_based_activation_conditions"
          : "activation_conditions"
      ][editingBucketIndex.value].parameter_override_active = isActive;
      configObj.value[
        isEditingUnitBucket.value
          ? "unit_based_activation_conditions"
          : "activation_conditions"
      ][editingBucketIndex.value].custom_parameters = state;
      parameterPopupOpen.value = false;
      editingBucketIndex.value = -1;
    };

    return {
      configObj,
      switchSystemIsActive,
      isLoading,
      submit,
      isSwitching,
      addConfiguration,
      removeConfiguration,
      toggleConditionPosition,
      addUnitPriority,
      removeUnitPriority,
      toggleUnitPriority,
      parameterPopupOpen,
      editingBucketIndex,
      units,
      toggleUnitConditionPosition,
      addUnitConfiguration,
      isEditingUnitBucket,
      removeUnitConfiguration,
      openParameterPopup,
      onPopupSave,
      isError,
      countryOptions: countryConstants.COUNTRY_MAP,
      modes: [
        {
          text: "Support Player",
          value: 0,
        },
        {
          text: "Support Bot",
          value: 1,
        },
        {
          text: "Support Both",
          value: 2,
        },
      ],
      userSegmentOptions: [
        {
          text: "Minnows",
          value: 0,
        },
        {
          text: "Dolphins",
          value: 1,
        },
        {
          text: "Whales",
          value: 2,
        },
      ],
    };
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0px;
}

.main-heading {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: brown;
  color: white;
  padding: 0 30px;
}

.inner-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
}

.content-container {
  width: 50%;
  text-align: left;
  align-self: stretch;
  padding: 20px 30px;
}

.full-width-content-container {
  text-align: left;
  align-self: stretch;
  padding: 20px 30px;
}

.field-container {
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.section {
  margin-bottom: 15px;
}

.inner {
  padding-left: 25px;
}

input {
  min-width: 100px;
  height: 35px;
  max-width: 85%;
  font-size: 14px;
  border: 1px solid gray;
  border-radius: 3px;
}

.input {
  height: 35px;
  max-height: 35;
  max-width: 85%;
  font-size: 14px;
  border: 1px solid gray;
  border-radius: 5px;
  margin: 10px;
}

.condition-container {
  width: 100%;
  margin: 5px;
  padding: 3px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 2fr 1fr 1fr 1fr;
  justify-content: stretch;
  align-items: center;
  border-bottom: 1px solid grey;
  align-content: center;
}

.smaller-grid {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr !important;
}

.unit-priority-container {
  grid-template-columns: 1fr 1fr !important;
}

.bold-font {
  font-weight: bold;
}

.activation-config-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 3px;
  padding-left: 10px;
}

.clickable-icon:hover {
  cursor: pointer;
}

hr.dashed {
  border-top: 1px dashed darkgray;
  width: 100%;
}
</style>
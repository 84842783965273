<template>
  <div>
    <div class="popup-wrapper" :style="!popupOpen ? { display: 'none' } : {}">
      <div class="popup-container">
        <h2>Edit Bucket (Advanced)</h2>
        <div class="field">
          <label>Parameter Override For Bucket</label>
          <input v-model="isActive" type="checkbox" />
        </div>
        <div class="field">
          <label>Unit Manipulation Probability</label>
          <input
            :disabled="!isActive"
            v-model="state.unit_manipulation_probability"
            type="number"
          />
        </div>
        <div class="field">
          <label>Hard Mode Unit Manipulation Probability</label>
          <input
            :disabled="!isActive"
            v-model="state.hard_mode_unit_manipulation_probability"
            type="number"
          />
        </div>
        <div class="field">
          <label>Grid Slot Manipulation Probability</label>
          <input
            :disabled="!isActive"
            v-model="state.grid_slot_manipulation_probability"
            type="number"
          />
        </div>
        <div class="field">
          <label>Hard Mode Grid Slot Manipulation Probability</label>
          <input
            :disabled="!isActive"
            v-model="state.hard_mode_grid_slot_manipulation_probability"
            type="number"
          />
        </div>
        <div class="field">
          <label>Chameleon Probability for Manipulated Summon</label>
          <input
            :disabled="!isActive"
            v-model="state.chameleon_probability_for_manipulated_unit"
            type="number"
          />
        </div>
        <div class="field">
          <label>Voodoo Outcome Manipulation Probability</label>
          <input
            :disabled="!isActive"
            v-model="state.voodoo_manipulation_probability"
            type="number"
          />
        </div>
        <div class="field">
          <label>Hard Mode Voodoo Outcome Manipulation Probability</label>
          <input
            :disabled="!isActive"
            v-model="state.hard_mode_voodoo_manipulation_probability"
            type="number"
          />
        </div>
        <div class="field">
          <label>Manipulated Critical Rate</label>
          <input
            :disabled="!isActive"
            v-model="state.manipulated_critical_rate"
            type="number"
          />
        </div>
        <div class="field">
          <label>Hard Mode Manipulated Critical Rate</label>
          <input
            :disabled="!isActive"
            v-model="state.hard_mode_manipulated_critical_rate"
            type="number"
          />
        </div>
        <h4 style="color: darkred">{{ errorText }}</h4>
        <div class="button-section">
          <Button
            text="Apply"
            type="success"
            size="md"
            :onClick="() => save()"
          />
          <Button
            text="Cancel"
            type="error"
            size="md"
            :onClick="() => cancel()"
          />
        </div>
        <div class="popup-footer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";

import Button from "../../../components/common/Button.vue";

export default {
  name: "ParameterOverridePopup",
  components: {
    Button,
  },
  props: ["isOverrideActive", "data", "itemIndex", "popupOpen"],
  emits: ["popup-close", "popup-save"],
  setup(props, { emit }) {
    const isActive = ref(false);
    const state = ref({});
    const errorText = ref("");

    const requiredParameters = [
      "unit_manipulation_probability",
      "hard_mode_unit_manipulation_probability",
      "grid_slot_manipulation_probability",
      "hard_mode_grid_slot_manipulation_probability",
      "chameleon_probability_for_manipulated_unit",
      "hard_mode_voodoo_manipulation_probability",
      "voodoo_manipulation_probability"
    ];

    watch(
      () => props.isOverrideActive,
      (data) => {
        isActive.value = data;
      }
    );
    watch(
      () => props.data,
      (data) => {
        state.value = data;
      }
    );

    const validate = () => {
      if (!isActive.value) return true;

      for(var param of requiredParameters) {
        if (!state.value[param] || state.value[param] > 100)
        return false
      }

      return true;
    };

    const clearState = () => {
      isActive.value = false;
      state.value = {};
      errorText.value = "";
    };

    const save = () => {
      if (validate()) {
        emit("popup-save", isActive.value, state.value);
        clearState();
      } else errorText.value = "Invalid parameters!";
    };

    const cancel = () => {
      clearState();
      emit("popup-close");
    };

    return {
      state,
      isActive,
      save,
      errorText,
      cancel,
    };
  },
};
</script>

<style scoped>
.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-container {
  width: 70%;
  border-radius: 15px;
  background-color: white;
  padding: 50px;
  display: flex;
  flex-direction: column;
}

.popup-content {
  width: 100%;
  height: 90%;
  align-self: flex-start;
}
.popup-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.popup-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.field {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.button-section {
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 100%;
}
</style>
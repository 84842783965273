<script>

export default {
    props: {
        type: {
            type: String,
            required: false,
            default: 'error'
        },
        text: {
            type: String,
            required: false,
            default: 'An Error Occured'
        }
    }
}
</script>

<template>
    <div class="error-container">
        <i class="fa fa-face-sad-tear fa-2xl" ></i>
        <h1>Oops!</h1>
        <p>{{text}}</p>
    </div>
</template>

<style scoped>

.error-container {
    padding-top: 75px;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 24px;
}

</style>